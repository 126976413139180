/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const ReactDOM = require(`react-dom/client`)

// You can delete this file if you're not using it
exports.onRouteUpdate = () => {
    setTimeout(function () {
        window.document.getElementsByTagName('html')[0].style.visibility = 'visible';
        window.document.getElementsByTagName('html')[0].style.opacity = '1';
    }, 150)
}

// IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
exports.onClientEntry = () => {
    if (typeof window.IntersectionObserver === 'undefined') {
        /* eslint-disable no-unused-expressions */
        import('intersection-observer')
    }
}

exports.replaceHydrateFunction = () => {
    return (element, container) => {
      const root = ReactDOM.createRoot(container)
      root.render(element)
    }
}
    